import revive_payload_client_hcp260Fu5s from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6ywfvsv5rb4l7stavppuezj344/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_lzwQQ9szyD from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6ywfvsv5rb4l7stavppuezj344/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4etftfcfRn from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6ywfvsv5rb4l7stavppuezj344/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_tHFpFStqxy from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6ywfvsv5rb4l7stavppuezj344/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_TBjDmeRbsR from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6ywfvsv5rb4l7stavppuezj344/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_jrcDl1KGHu from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6ywfvsv5rb4l7stavppuezj344/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_S3omSqQqFu from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.21.1_typescript@5.5.4_vue@3.4.30_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/jenkins/agent/workspace/platform-static front-category-prod/.nuxt/components.plugin.mjs";
import prefetch_client_2dDflspdli from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6ywfvsv5rb4l7stavppuezj344/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_qbZjsRoQop from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.4_rollup@4.21.1_vue@3.4.30_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_GlAz5SHdoI from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.4_rollup@4.21.1_vue@3.4.30_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_1nOnevJXRi from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.4.1_@types+node@22.5.0_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_6ywfvsv5rb4l7stavppuezj344/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _02_detectDevice_pxm8fCuh4v from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/02.detectDevice.ts";
import _03_lazyPlugin_Dd5b7eZc4K from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/03.lazyPlugin.ts";
import _04_errorMonitor_LOgjIHx1K2 from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/04.errorMonitor.ts";
import _05_loading_directive_BLzQLP4uua from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/05.loading.directive.ts";
import _06_liveChat_client_53hhWlm58s from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/06.liveChat.client.ts";
import _07_sentry_client_fpwQ0Adq5I from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/07.sentry.client.ts";
export default [
  revive_payload_client_hcp260Fu5s,
  unhead_lzwQQ9szyD,
  router_4etftfcfRn,
  debug_tHFpFStqxy,
  payload_client_TBjDmeRbsR,
  check_outdated_build_client_jrcDl1KGHu,
  plugin_vue3_S3omSqQqFu,
  components_plugin_KR1HBZs4kY,
  prefetch_client_2dDflspdli,
  switch_locale_path_ssr_qbZjsRoQop,
  i18n_GlAz5SHdoI,
  chunk_reload_client_1nOnevJXRi,
  _02_detectDevice_pxm8fCuh4v,
  _03_lazyPlugin_Dd5b7eZc4K,
  _04_errorMonitor_LOgjIHx1K2,
  _05_loading_directive_BLzQLP4uua,
  _06_liveChat_client_53hhWlm58s,
  _07_sentry_client_fpwQ0Adq5I
]